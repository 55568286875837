import React from 'react';
import CheckIcon from '@mui/icons-material/Check';
import RemoveIcon from '@mui/icons-material/Remove';
import { isString } from 'underscore';
import cancelAnytime from '../../../static/svgs/pricing/cancel-anytime.svg';
import fairPricing from '../../../static/svgs/pricing/fair-pricing.svg';
import freeTrial from '../../../static/svgs/pricing/free-trial.svg';

const createRow = (name, isBasic, isStandard, isPlus, isPro, info) => ({
  name,
  isBasic,
  isStandard,
  isPlus,
  isPro,
  info,
});

export const getCellContent = (value, t) => {
  if (isString(value)) return t(value);
  if (value) return <CheckIcon fontSize="small" />;
  return <RemoveIcon fontSize="small" />;
};

export const getAnnualBill = (users, price) => (users * price * 12).toFixed(2);
export const getMonthlyBill = (users, price) => (users * price).toFixed(2);

export const getArrayIndex = (name) => {
  if (name === 'pricing.basic') return `isBasic`;
  if (name === 'pricing.standard') return `isStandard`;
  if (name === 'pricing.plus') return `isPlus`;
  if (name === 'pricing.pro') return `isPro`;
};

export const pricingUsDifferent = [
  {
    name: 'pricing.fair_pricing_policy',
    description: 'pricing.biz_offers_free_and_paid_plans_all_our_plans_come',
    pic: fairPricing,
  },
  {
    name: 'pricing.cancel_anytime',
    description: 'pricing.at_the_end_of_your_trial_you_may_downgrade_from_yo',
    pic: cancelAnytime,
  },
  {
    name: 'pricing.free_trial',
    description: 'pricing.ever_bought_something_without_any_trial_thats_why',
    pic: freeTrial,
  },
];

export const pricingPlansList = [
  {
    name: 'pricing.basic',
    description: 'pricing.basic_description',
    monthlyPrice: 0,
    annualPrice: 0,
    arrayCount: 7,
    translationPath: 'pricing.basic_plan',
    isfree: true,
  },
  {
    name: 'pricing.standard',
    description: 'pricing.standard_description',
    monthlyPrice: 10,
    annualPrice: 7,
    arrayCount: 8,
    translationPath: 'pricing.standard_plan',
  },
  {
    name: 'pricing.plus',
    description: 'pricing.plus_description',
    monthlyPrice: 16,
    annualPrice: 11,
    isPopular: true,
    arrayCount: 8,
    translationPath: 'pricing.plus_plan',
  },
  {
    name: 'pricing.pro',
    description: 'pricing.pro_description',
    monthlyPrice: 21,
    annualPrice: 15,
    arrayCount: 8,
    translationPath: 'pricing.pro_plan',
  },
];

export const fundamentals = [
  createRow(
    'pricing.table.seats',
    '3',
    '10',
    'pricing.table.unlimited',
    'pricing.table.unlimited',
    'pricing.helpers.max_seats'
  ),

  // createRow(
  //   'pricing.table.access',
  //   true,
  //   true,
  //   true,
  //   true,
  //   'pricing.helpers.access_anywhere'
  // ),
  createRow(
    'pricing.table.column',
    true,
    true,
    true,
    true,
    'pricing.helpers.column_types'
  ),
  createRow(
    'pricing.table.custom_field_names',
    false,
    false,
    true,
    true,
    'pricing.helpers.custom_field_name'
  ),
  createRow(
    'pricing.table.written_update',
    true,
    true,
    true,
    true,
    'pricing.helpers.written_update'
  ),
  createRow(
    'pricing.table.custom_branding',
    false,
    false,
    true,
    true,
    'pricing.helpers.custom_branding'
  ),
  createRow(
    'pricing.table.activity_logs',
    'pricing.table.upto_30days',
    'pricing.table.upto_6months',
    'pricing.table.upto_1year',
    'pricing.table.unlimited',
    'pricing.helpers.activity_logs'
  ),
  createRow(
    'pricing.table.guest_access',
    '1',
    '3',
    '5',
    '10',
    'pricing.helpers.guest_access'
  ),
  createRow(
    'pricing.table.table_view',
    true,
    true,
    true,
    true,
    'pricing.helpers.table_view_1'
  ),
  createRow(
    'pricing.table.custom_filters',
    false,
    false,
    true,
    true,
    'pricing.helpers.custom_filters'
  ),
  createRow(
    'pricing.table.tag_members',
    false,
    true,
    true,
    true,
    'pricing.helpers.tag_members'
  ),
  createRow(
    'pricing.table.add_attachments',
    'pricing.table.max_10mb',
    'pricing.table.max_15mb',
    'pricing.table.max_20mb',
    'pricing.table.max_25mb',
    'pricing.helpers.add_attachments'
  ),
  createRow(
    'pricing.table.bulk_upload',
    false,
    false,
    true,
    true,
    'pricing.helpers.bulk_upload'
  ),
  createRow(
    'pricing.table.direct_pdf',
    false,
    true,
    true,
    true,
    'pricing.helpers.direct_pdf'
  ),
  createRow(
    'pricing.table.export_data',
    false,
    true,
    true,
    true,
    'pricing.helpers.export_data'
  ),
  createRow(
    'pricing.table.biz_apps_access',
    'pricing.table.limited',
    'pricing.table.limited',
    'pricing.table.unlimited',
    'pricing.table.unlimited',
    'pricing.helpers.biz_apps_access'
  ),
  createRow(
    'pricing.table.reports',
    false,
    false,
    true,
    true,
    'pricing.helpers.reports'
  ),
  createRow(
    'pricing.table.generate_estimates',
    'pricing.table.50_per_month',
    'pricing.table.100_per_month',
    'pricing.table.250_per_month',
    'pricing.table.unlimited'
  ),
  createRow(
    'pricing.table.generate_invoices',
    'pricing.table.100_per_month',
    'pricing.table.200_per_month',
    'pricing.table.500_per_month',
    'pricing.table.unlimited'
  ),
  createRow(
    'pricing.table.customise',
    false,
    false,
    'pricing.table.unlimited',
    'pricing.table.unlimited'
  ),
  createRow(
    'pricing.table.mlp',
    'pricing.table.50_per_month',
    'pricing.table.100_per_month',
    'pricing.table.250_per_month',
    'pricing.table.unlimited'
  ),
  createRow(
    'pricing.table.mdp',
    'pricing.table.100_per_month',
    'pricing.table.200_per_month',
    'pricing.table.500_per_month',
    'pricing.table.unlimited'
  ),
  createRow(
    'pricing.table.billable_customers',
    'pricing.table.50_per_month',
    'pricing.table.100_per_month',
    'pricing.table.250_per_month',
    'pricing.table.unlimited'
  ),
  createRow(
    'pricing.table.vendors',
    false,
    false,
    'pricing.table.100_per_month',
    'pricing.table.unlimited'
  ),
  createRow(
    'pricing.table.po',
    'pricing.table.25_per_month',
    'pricing.table.50_per_month',
    'pricing.table.100_per_month',
    'pricing.table.unlimited'
  ),
  createRow(
    'pricing.table.im_items',
    'pricing.table.50_items_per_month',
    'pricing.table.100_items_per_month',
    'pricing.table.250_items_per_month',
    'pricing.table.unlimited'
  ),
  createRow(
    'pricing.table.im_services',
    false,
    'pricing.table.100_services_per_month',
    'pricing.table.250_services_per_month',
    'pricing.table.unlimited'
  ),
  // createRow(
  //   'pricing.table.checkout',
  //   true,
  //   true,
  //   true,
  //   true,
  //   'pricing.helpers.checkout_links'
  // ),
  createRow('pricing.table.ledger', false, false, false, true),
  createRow('pricing.table.signature', true, true, true, true),
  createRow('pricing.table.e_signature', false, false, false, true),
  createRow(
    'pricing.table.new_workspace',
    false,
    'pricing.table.max_2',
    'pricing.table.max_5',
    'pricing.table.max_10'
  ),
];

export const security = [
  createRow(
    'pricing.table.two_factor',
    false,
    false,
    true,
    true,
    'pricing.helpers.two_factor'
  ),
  createRow(
    'pricing.table.google_signin',
    false,
    false,
    true,
    true,
    'pricing.helpers.google_signin'
  ),
  // createRow(
  //   'pricing.table.microsoft_signin',
  //   false,
  //   false,
  //   true,
  //   true,
  //   'pricing.helpers.microsoft_signin'
  // ),
  // createRow(
  //   'pricing.table.linkedin_signin',
  //   false,
  //   false,
  //   true,
  //   true,
  //   'pricing.helpers.linkedin_signin'
  // ),
  // createRow(
  //   'pricing.table.hipaa',
  //   false,
  //   false,
  //   true,
  //   true,
  //   'pricing.helpers.hipaa'
  // ),
  createRow('pricing.table.import_and_export', false, false, true, true),
  createRow(
    'pricing.table.uptime_sla',
    false,
    false,
    true,
    true,
    'pricing.helpers.uptime_sla'
  ),
  createRow(
    'pricing.table.permissions',
    false,
    false,
    true,
    true,
    'pricing.helpers.permissions'
  ),
  // createRow(
  //   'pricing.table.security_controls',
  //   false,
  //   false,
  //   false,
  //   true,
  //   'pricing.helpers.security_controls'
  // ),
];

export const support = [
  // createRow(
  //   'pricing.table.self_service',
  //   true,
  //   true,
  //   true,
  //   true,
  //   'pricing.helpers.self_service'
  // ),
  // createRow(
  //   'pricing.table.dedicated_manager',
  //   false,
  //   false,
  //   true,
  //   true,
  //   'pricing.helpers.dedicated_manager'
  // ),
  createRow(
    'pricing.table.email_support',
    true,
    true,
    true,
    true,
    'pricing.helpers.email_support'
  ),
  createRow(
    'pricing.table.call_support',
    false,
    false,
    false,
    true,
    'pricing.helpers.call_support'
  ),
  createRow(
    'pricing.table.priority_support',
    false,
    false,
    false,
    true,
    'pricing.helpers.priority_support'
  ),
];
