import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { range } from 'underscore';
import { Button } from '../../components/Button';
import Switch from '../../components/Switch';
import TransHTML from '../../components/TransHTML';
import {
  getAnnualBill,
  getMonthlyBill,
  pricingPlansList,
} from './Pricing.helper';
import { RedirectToSSOSignUp } from '../../util';
import { FONT_BOLDER, screenLessThan } from '../../shared/styles';
import tick from '../../../static/svgs/features/ticky.svg';

const PricingBlocks = ({ isAnnual, usersCount, handleIsAnnual }) => {
  const { t } = useTranslation();

  return (
    <PlansWrapper>
      <StyledToggle
        checked={isAnnual}
        handleChange={handleIsAnnual}
        textLeft={t('pricing.monthly')}
        textRight={
          t('pricing.discount-label') && (
            <span>
              {t('pricing.discount-label')}&nbsp;
              <DisplayBox> {t('pricing.discount-label-2')} </DisplayBox>
            </span>
          )
        }
      />
      <div className="table-row">
        <div className="table-cell-title" />
        {pricingPlansList.map((eachPlan, index) => (
          <PricingBlock key={index} className={`table-cell-${index + 1}`}>
            <CellWrapper>
              <span className="plan-name">{t(eachPlan.name)}</span>
              <span className="plan-description">
                {t(eachPlan.description)}
              </span>
              <span className="plan-price">
                <span className="currency-logo">$&nbsp;</span>
                {isAnnual ? eachPlan.annualPrice : eachPlan.monthlyPrice}.00
                <span className="plan-bill-text">
                  &nbsp;&nbsp;
                  <TransHTML
                    contentKey={t(
                      'pricing.user_less_than_br_greater_than_month'
                    )}
                  />
                </span>
              </span>

              <span className="plan-billed">
                {eachPlan.isfree
                  ? t('home.biz-features.as_long')
                  : isAnnual
                  ? `${t('pricing.billed_yearly_at')} ${getAnnualBill(
                      usersCount,
                      eachPlan.annualPrice
                    )}`
                  : `${t('pricing.billed_monthly_at')} ${getMonthlyBill(
                      usersCount,
                      eachPlan.monthlyPrice
                    )}`}
              </span>

              <StartFreeButton
                style={{ width: '90%' }}
                onClick={RedirectToSSOSignUp}
              >
                {t('pricing.start_for_free')}
              </StartFreeButton>
              <div style={{ width: '100%', paddingTop: '3%' }}>
                <div key={index} className="plan-bullet-points">
                  {eachPlan.name === 'pricing.standard' && (
                    <b>{t('everything_in_free_and')}</b>
                  )}
                  {eachPlan.name === 'pricing.plus' && (
                    <b>{t('everything_in_standard_and')}</b>
                  )}
                  {eachPlan.name === 'pricing.pro' && (
                    <b>{t('everything_in_plus_and')}</b>
                  )}
                </div>
                {range(eachPlan.arrayCount).map((index) => (
                  <div key={index} className="plan-bullet-points">
                    <span>
                      <img src={tick} />
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      {t(`${eachPlan.translationPath}.feature_${index + 1}`)}
                    </span>
                  </div>
                ))}
              </div>
            </CellWrapper>
          </PricingBlock>
        ))}
      </div>
    </PlansWrapper>
  );
};

PricingBlocks.propTypes = {
  isAnnual: PropTypes.bool,
  usersCount: PropTypes.number,
  handleIsAnnual: PropTypes.func,
};

export default PricingBlocks;

const StartFreeButton = styled(Button)`
  height: 45px;
`;

const PricingBlock = styled.div`
  min-height: 43rem;
  ${screenLessThan('950px')} {
    display: none;
  }
`;

const DisplayBox = styled.span`
  background-color: rgb(250, 97, 70, 0.1);
  border-radius: 4px;
  padding: 9px;
`;

const PlansWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 0 30px;
  position: relative;
  width: 100%;

  .table-row {
    align-items: center;
    display: flex;
    justify-content: center;
    position: relative;

    .table-cell-title {
      text-align: center;
    }
    .table-cell-1,
    .table-cell-2,
    .table-cell-3,
    .table-cell-4 {
      background-color: white;
      border-radius: 4px;
      box-shadow: 10px 24px 44px #00000008;
      justify-content: center;
      margin-left: 1%;
      margin-right: 1%;
      padding: 2rem 0 3%;
      width: 24%;
      .MuiSvgIcon-root {
        font-size: 14px;
      }
    }
  }
`;

const CellWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  .plan-name {
    font-size: 28px;
    font-weight: 600;
  }
  .plan-description {
    font-size: 14px;
    margin: 4% 0;
    text-align: center;
  }
  .plan-bullet-points {
    text-align: left;
    font-size: 14px;
    font-weight: 500;
    margin: 9% 0 0% 10%;
    width: 80%;
  }

  .plan-name,
  .plan-billed,
  .plan-bill-text {
    line-height: unset;
  }
  .plan-price {
    font-size: 50px;
    ${FONT_BOLDER}
    line-height: unset;
    margin: 1rem 0;
    ${screenLessThan('1400px')} {
      font-size: 42px;
    }
    ${screenLessThan('1100px')} {
      font-size: 30px;
    }
  }
  .currency-logo {
    font-weight: 500;
    font-size: 30px;
  }
  .plan-billed,
  .plan-bill-text {
    display: inline-block;
    font-size: 15px;
    font-weight: 100;
    line-height: 1;
    margin-bottom: 1rem;
    text-align: left;
  }

  ${screenLessThan('1350px')} {
    img.arrow {
      display: none;
    }
  }
`;

const StyledToggle = styled(Switch)`
  justify-content: right;
  margin-bottom: 2rem;
  margin-top: 1rem;
  ${screenLessThan('950px')} {
    display: none;
  }
`;
