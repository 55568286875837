import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import SliderInput from '../../components/SliderInput';
import sliderNextImgSrc from '../../../static/svgs/pricing/slider-next-icon.svg';

const marks = [
  {
    value: 1,
    label: '1',
  },
  {
    value: 100,
    label: '100',
  },
];

const PricingSlider = ({ usersCount, handleUsersCount }) => {
  const { t } = useTranslation();
  return (
    <>
      <DescriptionWrapper>
        <img src={sliderNextImgSrc} alt="slider-icon" />
        <span className="description-text">
          {t('pricing.choose_your_team_size')}
        </span>
      </DescriptionWrapper>
      <SliderInput
        defaultValue={1}
        value={usersCount}
        getAriaValueText={(value) => value}
        onChange={handleUsersCount}
        aria-labelledby="discrete-slider-always"
        min={1}
        marks={marks}
        valueLabelDisplay="on"
      />
    </>
  );
};

PricingSlider.propTypes = {
  usersCount: PropTypes.number,
  handleUsersCount: PropTypes.func,
};
export default PricingSlider;

const DescriptionWrapper = styled.div`
  align-items: center;
  flex-direction: row;
  margin: 0 0 0rem;
  text-align: center;
  img {
    margin-right: 10px;
    width: 7px;
  }
  span.description-text {
    font-size: 16px;
  }
`;
