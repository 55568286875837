import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { P } from '../Text';
import { screenLessThan } from '../../shared/styles';
import aps from '../../../static/svgs/pricing/aps.svg';
import amazonSSL from '../../../static/svgs/pricing/secure-ssl.svg';

const AmazonCertified = () => {
  const { t } = useTranslation();
  return (
    <AmazonContainer>
      <AmazonSSL src={amazonSSL} />
      <AmazonSecureText>
        {t('pricing.100_secure_ssl_payments_powered_by_amazon_payment')}
      </AmazonSecureText>
      <br />
      <AmazonImg src={aps} />
    </AmazonContainer>
  );
};

export default AmazonCertified;

const AmazonSecureText = styled(P)`
  display: inline-block;
`;
const AmazonSSL = styled.img`
  display: inline-block;
  margin-bottom: 0.2rem;
  margin-right: 0.5rem;
  vertical-align: middle;

  width: 12px;
`;

const AmazonImg = styled.img`
  margin-bottom: 5%;
`;

const AmazonContainer = styled.div`
  margin-top: 2%;
  text-align: center;
  ${screenLessThan('950px')} {
    display: none;
  }
`;
