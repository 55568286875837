import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import { H1 } from '../../components/Text';
import PricingTableCustomRow from './PricingTableCustomRow';
import PricingTableHeaderCell from './PricingTableHeaderCell';
import PricingTableStickyCell from './PricingTableStickyCell';
import { fundamentals, security, support } from './Pricing.helper';
import { FONT_BOLD, PRIMARY_COLOR } from '../../shared/styles';

const PricingTableHeaderRow = ({ title }) => (
  <div className="table-row header-row">
    <div className="table-cell-title">{title}</div>
    <div className="table-cell-1" />
    <div className="table-cell-2" />
    <div className="table-cell-3" />
    <div className="table-cell-4" />
  </div>
);

const PricingTable = ({ isAnnual, usersCount }) => {
  const { t } = useTranslation();

  return (
    <div>
      <PricingTableHeader>{t('compare_plans')}</PricingTableHeader>
      <PricingTableStickyCell usersCount={usersCount} isAnnual={isAnnual} />
      <PlansWrapper>
        <PricingTableHeaderCell usersCount={usersCount} isAnnual={isAnnual} />
        <PricingTableHeaderRow title={t('pricing.fundamentals')} />
        {fundamentals.map((row) => (
          <PricingTableCustomRow key={row.name} row={row} />
        ))}
        <PricingTableHeaderRow title={t('pricing.data_and_security')} />
        {security.map((row) => (
          <PricingTableCustomRow key={row.name} row={row} />
        ))}
        <PricingTableHeaderRow title={t('pricing.support')} />
        {support.map((row) => (
          <PricingTableCustomRow key={row.name} row={row} />
        ))}
      </PlansWrapper>
    </div>
  );
};

PricingTable.propTypes = {
  isAnnual: PropTypes.bool,
  usersCount: PropTypes.number,
};
PricingTableHeaderRow.propTypes = {
  title: PropTypes.string,
};
export default PricingTable;

const PricingTableHeader = styled(H1)`
  font-weight: 600;
  padding: 3rem 3rem 5rem;
  text-align: center;
`;

const PlansWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 0 30px;
  width: 100%;

  .table-row {
    display: flex;
    .table-cell-title,
    .table-cell-1,
    .table-cell-2,
    .table-cell-3,
    .table-cell-4 {
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      font-size: 14px;
    }
    .table-cell-title {
      justify-content: flex-start;
      width: 24%;
    }
    .table-cell-1,
    .table-cell-2,
    .table-cell-3,
    .table-cell-4 {
      justify-content: center;
      width: 19%;
      .MuiSvgIcon-root {
        font-size: 14px;
      }
    }
    .table-cell-1,
    .table-cell-2,
    .table-cell-4 {
      margin: 0 0 0 2%;
      padding: 1rem 0;
    }
    .table-cell-3 {
      margin-left: 2%;
      padding: 1rem 1%;
    }
    &:last-child {
      .table-cell-3 {
        border-bottom: 4px solid rgba(250, 97, 70, 0.1);
      }
    }
  }

  .header-row {
    border-bottom: 1px solid #e3e3e6;
    .table-cell-title {
      font-size: 24px;
      padding-bottom: 10px;
      padding-top: 1rem;
      ${FONT_BOLD}
    }
  }
  .sticky-row {
    position: relative;
    .table-cell-1,
    .table-cell-2,
    .table-cell-4 {
      border-top: 45px solid transparent;
    }
    .table-cell-3 {
      border-top: 45px solid rgba(250, 97, 70, 0.1);
      border-top-left-radius: 25px;
      border-top-right-radius: 25px;
    }
  }

  .table-cell-1,
  .table-cell-2,
  .table-cell-4 {
    background-color: transparent;
  }
  .table-cell-3 {
    border-left: 4px solid rgba(250, 97, 70, 0.1);
    border-right: 4px solid rgba(250, 97, 70, 0.1);
  }

  .isPopular {
    color: ${PRIMARY_COLOR};
    font-size: 10px;
    letter-spacing: 3px;
    position: absolute;
    text-transform: uppercase;
    top: 20px;
  }
`;
