import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { ButtonWithArrow } from '../../components/Button';
import PricingAccordionSections from './PricingAccordionSections';
import {
  fundamentals,
  getAnnualBill,
  getMonthlyBill,
  pricingPlansList,
  security,
  support,
} from './Pricing.helper';
import { RedirectToSSOSignUp } from '../../util';
import { FONT_MEDIUM, PRIMARY_COLOR } from '../../shared/styles';

const PricingAccordions = ({ usersCount, isAnnual }) => {
  const { t } = useTranslation();
  return pricingPlansList.map((eachPlan, i) => (
    <Accordion square key={i}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <span>{t(eachPlan.name)}</span>
        <span>
          {isAnnual
            ? getAnnualBill(usersCount, eachPlan.annualPrice)
            : getMonthlyBill(usersCount, eachPlan.monthlyPrice)}
        </span>
      </AccordionSummary>
      <AccordionDetails>
        <AccordionCenteredWrap>
          <ButtonWithArrow onClick={RedirectToSSOSignUp}>
            {t('pricing.try_for_free')}
          </ButtonWithArrow>
        </AccordionCenteredWrap>

        <PricingAccordionSections
          title={t('pricing.fundamentals')}
          planName={eachPlan.name}
          pricingPlanList={fundamentals}
        />

        <AccordionDetailsHr />

        <PricingAccordionSections
          title={t('pricing.data_and_security')}
          planName={eachPlan.name}
          pricingPlanList={security}
        />

        <AccordionDetailsHr />

        <PricingAccordionSections
          title={t('pricing.support')}
          planName={eachPlan.name}
          pricingPlanList={support}
        />

        <AccordionCenteredWrap top>
          <ButtonWithArrow onClick={RedirectToSSOSignUp}>
            {t('pricing.try_for_free')}
          </ButtonWithArrow>
        </AccordionCenteredWrap>
      </AccordionDetails>
    </Accordion>
  ));
};

const Accordion = styled(MuiAccordion)`
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 10px;
  box-shadow: none !important;
  margin-bottom: 1rem;
  width: 100%;
  &:before {
    display: none;
  }
  &.Mui-expanded {
    border: 1px solid ${PRIMARY_COLOR};
    margin: auto;
    margin-bottom: 1rem;
  }
`;

const AccordionSummary = styled(MuiAccordionSummary)`
  &.MuiAccordionSummary-root {
    margin-bottom: -1;
    min-height: 56px;
    font-size: 16px;
    ${FONT_MEDIUM};
    &.Mui-expanded {
      min-height: 56px;
    }
  }
  & .MuiAccordionSummary-content {
    justify-content: space-between;
    &.Mui-expanded {
      margin: 12px 0;
    }
  }
  & .MuiAccordionSummary-expandIconWrapper {
    margin-left: 12px;
  }
`;
const AccordionDetails = styled(MuiAccordionDetails)`
  flex-direction: column;
  padding: 1rem;
`;

const AccordionCenteredWrap = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
  button {
    margin-bottom: 3rem;
    width: 90%;
    ${(props) => props.top && `margin-top: 3rem; margin-bottom: 1rem;`}
  }
`;

const AccordionDetailsHr = styled.hr`
  border-top: 0;
  margin: 1.5rem 0;
`;
PricingAccordions.propTypes = {
  isAnnual: PropTypes.bool,
  value: PropTypes.string,
  currencies: PropTypes.number,
};

export default PricingAccordions;
