/* eslint-disable import/no-unused-modules */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import AmazonCertified from '../components/AmazonSecured/AmazonSecured';
import FaqAccordion from '../components/FaqAccordion';
import Helmet from '../components/Helmet';
import HomeImageList from '../components/List/HomeImageList';
import PricingAccordions from '../modules/pricing/PricingAccordions';
import PricingBlocks from '../modules/pricing/PricingBlocks';
import PricingDifferent from '../modules/pricing/PricingDifferent';
import PricingHeader from '../modules/pricing/PricingHeader';
import PricingInfo from '../modules/pricing/PricingInfo';
import PricingSlider from '../modules/pricing/PricingSlider';
import PricingTable from '../modules/pricing/PricingTable';
import { PageLayout } from '../shared/layouts';
import withI18n from '../hocs/withI18n';
import useWindowDimensions from '../hooks/useWindowDimensions';
import { pricingFaqs } from '../modules/faqs/Faqs.helper';
import { CLIENT_LIST } from '../config';

export const Head = withI18n(() => {
  return <Helmet pageName="pricing" />;
});

const Pricing = () => {
  const { t } = useTranslation('');

  const { width } = useWindowDimensions();

  const [usersCount, setUsersCount] = useState(1);
  const [isAnnual, setIsAnnual] = useState(true);

  const handleUsersCount = (event, newValue) => setUsersCount(newValue);
  const handleIsAnnual = () => setIsAnnual(!isAnnual);

  return (
    <PageLayout>
      <PricingHeader
        isAnnual={isAnnual}
        handleIsAnnual={handleIsAnnual}
        t={t}
      />
      <PricingSlider {...{ usersCount, handleUsersCount, t }} />
      <PricingBlocks
        usersCount={usersCount}
        isAnnual={isAnnual}
        handleIsAnnual={handleIsAnnual}
      />
      <AmazonCertified />
      {width < 990 ? (
        <PricingAccordions usersCount={usersCount} isAnnual={isAnnual} />
      ) : (
        <PricingTable usersCount={usersCount} isAnnual={isAnnual} />
      )}

      <PricingInfo />
      <HomeImageList title="home.loved_by_teams" ImageList={CLIENT_LIST} />
      <PricingDifferent />
      <FaqAccordion faqList={pricingFaqs} />
    </PageLayout>
  );
};

Pricing.propTypes = {
  language: PropTypes.object,
};
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default Pricing;
