import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { H2, P } from '../../components/Text';
import { pricingUsDifferent } from './Pricing.helper';
import { screenLessThan } from '../../shared/styles';

const PricingDifferent = () => {
  const { t } = useTranslation();

  return (
    <PlansWrapper>
      <DifferentHeading>
        {t('pricing.what_makes_us_different')}
      </DifferentHeading>
      <div className="table-row">
        <div className="table-cell-title" />
        {pricingUsDifferent.map((eachPlan, index) => (
          <div key={index} className={`table-cell-${index + 1}`}>
            <CellWrapper>
              <DifferentImage src={eachPlan.pic} />
              <span className="plan-name">{t(eachPlan.name)}</span>
              <P className="plan-description">{t(eachPlan.description)}</P>
            </CellWrapper>
          </div>
        ))}
      </div>
    </PlansWrapper>
  );
};

export default PricingDifferent;

const DifferentHeading = styled(H2)`
  font-weight: 600;
  padding-bottom: 2rem;
`;

const DifferentImage = styled.img`
  width: 100%;
`;

const PlansWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 6rem 0 7rem;
  position: relative;
  width: 100%;
  .table-row {
    align-items: center;
    display: flex;
    justify-content: center;
    position: relative;

    .table-cell-title {
    }
    .table-cell-1,
    .table-cell-2,
    .table-cell-3,
    .table-cell-4 {
      background-color: white;
      border-radius: 4px;
      box-shadow: 10px 24px 44px #00000008;
      justify-content: center;
      text-align: center !important;
      margin-left: 1%;
      margin-right: 1%;
      padding: 0rem 0 5% 0;
      width: 24%;
      .MuiSvgIcon-root {
        font-size: 14px;
      }
    }
  }

  ${screenLessThan('950px')} {
    display: none;
  }
`;

const CellWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 400px;
  position: relative;
  .plan-name {
    font-size: 28px;
    font-weight: 600;
    margin-top: 2rem;
  }
  .plan-description {
    font-size: 14px;
    margin: 4% 5%;
    margin-top: 1rem;
    text-align: center;
  }
`;
