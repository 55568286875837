import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Switch from '../../components/Switch';
import { H1 } from '../../components/Text';
import { PRIMARY_COLOR, screenLessThan } from '../../shared/styles';

const PricingHeader = ({ isAnnual, handleIsAnnual, t }) => (
  <HeaderWrapper>
    <PricingTitle>
      {t('pricing.html_title')}&nbsp;
      <span style={{ color: PRIMARY_COLOR }}>
        {t('pricing.html_title_free')}
      </span>
    </PricingTitle>
    <PricingDescription>{t('pricing.html_title_subtitle')}</PricingDescription>
    <StyledToggle
      checked={isAnnual}
      handleChange={handleIsAnnual}
      textLeft={t('pricing.monthly')}
      textRight={
        t('pricing.discount-label') && (
          <span>
            {t('pricing.discount-label')}&nbsp;
            <DisplayBox> {t('pricing.discount-label-2')} </DisplayBox>
          </span>
        )
      }
    />
  </HeaderWrapper>
);

export default PricingHeader;

PricingHeader.propTypes = {
  isAnnual: PropTypes.bool,
  handleIsAnnual: PropTypes.func,
  t: PropTypes.func,
};

const DisplayBox = styled.span`
  background-color: rgb(250, 97, 70, 0.1);
  border-radius: 4px;
  padding: 9px;
`;

const StyledToggle = styled(Switch)`
  display: none;
  ${screenLessThan('950px')} {
    display: flex;
    margin-top: 1rem;
  }
`;

const PricingTitle = styled(H1)`
  font-weight: 600;
  ${screenLessThan('768px')} {
    text-align: left;
  }
`;

const PricingDescription = styled.p`
  padding: 1% 0;
  ${screenLessThan('768px')} {
    text-align: left;
  }
`;

const HeaderWrapper = styled.div`
  flex-direction: column;
  justify-content: center;
  margin-bottom: 1rem;
  padding: 0 0 40px 0;
  text-align: center;
  width: 100%;
  ${screenLessThan('768px')} {
    flex-direction: row;
  }
`;
