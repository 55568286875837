import React from 'react';
import styled from 'styled-components';
import Slider from '@mui/material/Slider';
import { PRIMARY_COLOR } from '../../shared/styles';

/**
 * Progess bar to select value from range
 * Referenced to MUI Slider
 */
const SliderInput = (props) => (
  <SliderInputWrapper>
    <PrettoSlider {...props} />
  </SliderInputWrapper>
);

const SliderInputWrapper = styled.div`
  padding: 0 8px;
`;

const PrettoSlider = styled(Slider)({
  color: '#f1f1f1',
  height: 8,
  '& .MuiSlider-thumb': {
    height: 16,
    width: 16,
    backgroundColor: '#010101',
    border: '2px solid #ffffff',
    color: PRIMARY_COLOR,
    boxShadow: '0px 0px 17px -2px rgba(0, 0, 0, 70%)',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: '0px 0px 17px -2px rgba(0, 0, 0, 70%)',
    },
    '&:before': {
      display: 'none',
    },
  },
  '& .MuiSlider-valueLabel': {
    lineHeight: 1.2,
    fontSize: 12,
    background: 'unset',
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: '50% 50% 50% 0',
    backgroundColor: PRIMARY_COLOR,
    transformOrigin: 'bottom left',
    transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
    '&:before': { display: 'none' },
    '&.MuiSlider-valueLabelOpen': {
      transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
    },
    '& > *': {
      transform: 'rotate(45deg)',
    },
  },
  '& .MuiSlider-track': {
    height: 4,
    borderRadius: 4,
    color: PRIMARY_COLOR,
  },
  '& .MuiSlider-rail': {
    height: 5,
    borderRadius: 4,
    backgroundColor: '#e9e9ed',
    opacity: 1,
  },
  '& .MuiSlider-mark': {
    width: 0,
    height: 0,
  },
  '& .MuiSlider-markLabel': {
    color: '#8996B0',
  },
});

export default SliderInput;
