import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { getArrayIndex, getCellContent } from './Pricing.helper';
import { FONT_MEDIUM } from '../../shared/styles';

const PricingAccordionSections = ({ title, planName, pricingPlanList }) => {
  const { t } = useTranslation();
  return (
    <>
      <AccordionDetailsTitle>{title}</AccordionDetailsTitle>
      {pricingPlanList.map((row, index) => (
        <AccordionTableDataRow key={index}>
          <span>{t(row.name)}</span>
          <span>{getCellContent(row[getArrayIndex(planName)], t)}</span>
        </AccordionTableDataRow>
      ))}
    </>
  );
};

PricingAccordionSections.propTypes = {
  title: PropTypes.node,
  planName: PropTypes.string,
  pricingPlanList: PropTypes.array,
};

export default PricingAccordionSections;

const AccordionTableDataRow = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  span {
    font-size: 14px;
  }
`;
const AccordionDetailsTitle = styled.p`
  font-size: 16px;
  ${FONT_MEDIUM};
`;
