import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Button } from '../../components/Button';
import {
  pricingPlansList,
  getMonthlyBill,
  getAnnualBill,
} from './Pricing.helper';
import { RedirectToSSOSignUp } from '../../util';
import { FONT_BOLDER, FONT_REGULAR, screenLessThan } from '../../shared/styles';

const PricingTableHeaderCell = ({ isAnnual, usersCount }) => {
  const { t } = useTranslation();
  return (
    <div className="table-row sticky-row">
      <div className="table-cell-title" />
      {pricingPlansList.map((eachPlan, index) => (
        <div key={index} className={`table-cell-${index + 1}`}>
          {eachPlan.isPopular && (
            <span className="isPopular">{t('pricing.popular')}</span>
          )}
          <CellWrapper>
            <span className="plan-name">{t(eachPlan.name)}</span>
            <span className="plan-price">
              <span className="currency-logo">$&nbsp;</span>
              {isAnnual ? eachPlan.annualPrice : eachPlan.monthlyPrice}.00
            </span>
            <span className="plan-bill-text">
              {t('pricing.per_seat_per_month')}
            </span>
            <span className="plan-billed">
              {eachPlan.isfree
                ? t('home.biz-features.as_long')
                : isAnnual
                ? t('pricing.billed_yearly_at')
                : t('pricing.billed_monthly_at')}
              &nbsp; &nbsp;
              {!eachPlan.isfree &&
                (isAnnual
                  ? `$${getAnnualBill(usersCount, eachPlan.annualPrice)}`
                  : `$${getMonthlyBill(usersCount, eachPlan.annualPrice)}`)}
            </span>
            <TryButton onClick={RedirectToSSOSignUp}>
              {t('pricing.try_for_free')}
            </TryButton>
          </CellWrapper>
        </div>
      ))}
    </div>
  );
};

PricingTableHeaderCell.propTypes = {
  isAnnual: PropTypes.bool,
  usersCount: PropTypes.number,
};

export default PricingTableHeaderCell;

const TryButton = styled(Button)`
  height: 35px;
  width: 100%;
`;

const CellWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  .plan-name {
    font-size: 25px;
  }
  .plan-name,
  .plan-billed,
  .plan-bill-text {
    ${FONT_REGULAR}
    line-height: unset;
  }
  .plan-price {
    font-size: 50px;
    ${FONT_BOLDER}
    line-height: unset;
    margin: 1rem 0;
    ${screenLessThan('1400px')} {
      font-size: 42px;
    }
    ${screenLessThan('1100px')} {
      font-size: 30px;
    }
  }
  .currency-logo {
    font-weight: 500;
    font-size: 30px;
  }
  .plan-billed,
  .plan-bill-text {
    font-size: 15px;
    margin-bottom: 1rem;
    text-align: center;
  }

  ${screenLessThan('1350px')} {
    img.arrow {
      display: none;
    }
  }
`;
