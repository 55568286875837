import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Button } from '../../components/Button';
import {
  getMonthlyBill,
  pricingPlansList,
  getAnnualBill,
} from './Pricing.helper';
import { RedirectToSSOSignUp } from '../../util';
import { FONT_BOLDER, FONT_REGULAR, screenLessThan } from '../../shared/styles';

const PricingTableStickyCell = ({ isAnnual, usersCount }) => {
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset);
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
  }, []);
  const { t, i18n } = useTranslation();
  return (
    <PlansWrapper dir={i18n.dir()} offset={offset}>
      <div className="table-row sticky-row">
        <div className="table-cell-title" />
        {pricingPlansList.map((eachPlan, index) => (
          <div key={index} className={`table-cell-${index + 1}`}>
            <CellWrapper dir={i18n.dir()} offset={offset}>
              <span className="plan-name">{t(eachPlan.name)}</span>
              <span className="plan-price">
                <span className="currency-logo">$&nbsp;</span>
                {isAnnual ? eachPlan.annualPrice : eachPlan.monthlyPrice}.00
              </span>
              <PerSeat dir={i18n.dir()}>
                {t('pricing.per_seat_per_month')}
              </PerSeat>
              <span className="plan-billed">
                {eachPlan.isfree
                  ? t('home.biz-features.as_long')
                  : isAnnual
                  ? t('pricing.billed_yearly_at')
                  : t('pricing.billed_monthly_at')}
                &nbsp; &nbsp;
                {!eachPlan.isfree &&
                  (isAnnual
                    ? `$${getAnnualBill(usersCount, eachPlan.annualPrice)}`
                    : `$${getMonthlyBill(usersCount, eachPlan.annualPrice)}`)}
              </span>
              <TryButton dir={i18n.dir()} onClick={RedirectToSSOSignUp}>
                {t('pricing.try_for_free')}
              </TryButton>
            </CellWrapper>
          </div>
        ))}
      </div>
    </PlansWrapper>
  );
};

PricingTableStickyCell.propTypes = {
  isAnnual: PropTypes.bool,
  usersCount: PropTypes.number,
};

export default PricingTableStickyCell;

const TryButton = styled(Button)`
  height: 35px;
  margin-top: ${(props) => (props?.dir === 'rtl' ? '0' : '1rem')};
  width: 60%;
`;

const PlansWrapper = styled.div`
  background-color: #fafafa;
  border-top: 1px solid #e8ecee;
  box-shadow: 2px 3px 1px 0px #e8ecee;
  display: flex;
  flex-direction: column;
  left: auto;
  position: fixed;
  right: 0px;
  top: 85px;
  width: 100%;
  z-index: 10;
  ${(props) =>
    (
      props?.dir === 'rtl'
        ? 1630 <= props.offset && props.offset <= 3800
        : 1680 <= props.offset && props.offset <= 3630
    )
      ? css`
          display: flex;
        `
      : css`
          display: none;
        `}
  .table-row {
    display: flex;
    justify-content: flex-end;
    padding: 7.5rem 50px 7.5rem 0px;
    .table-cell-1 {
      width: 17%;
    }
    .table-cell-2,
    .table-cell-3 {
      width: 18.5%;
    }
    .table-cell-4 {
      width: 19.8%;
      .MuiSvgIcon-root {
        font-size: 14px;
      }
    }
  }
`;

const CellWrapper = styled.div`
  align-items: center;
  background-color: transparent;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 20px;
  padding-top: 20px;
  position: fixed;
  top: 87px;
  width: 18%;
  z-index: 999;
  ${(props) =>
    (
      props?.dir === 'rtl'
        ? 1680 <= props.offset && props.offset <= 3800
        : 1630 <= props.offset && props.offset <= 3630
    )
      ? css`
          display: flex;
        `
      : css`
          display: none;
        `}

  .plan-name {
    font-size: ${(props) => (props?.dir === 'rtl' ? '20px' : '25px')};
  }
  .plan-name,
  .plan-billed,
  .plan-bill-text {
    ${FONT_REGULAR}
    line-height: unset;
  }
  .plan-price {
    font-size: 50px;
    ${FONT_BOLDER}
    line-height: unset;
    ${screenLessThan('1400px')} {
      font-size: 42px;
    }
    ${screenLessThan('1100px')} {
      font-size: 30px;
    }
  }
  .currency-logo {
    font-weight: 500;
    font-size: 30px;
  }
  .plan-billed {
    font-size: 15px;
    margin-bottom: 0.5rem;
    text-align: center;
  }

  ${screenLessThan('1350px')} {
    img.arrow {
      display: none;
    }
  }
`;

const PerSeat = styled.span`
  font-size: 15px;
  margin-bottom: ${(props) => (props?.dir === 'rtl' ? '0' : '1rem')};
  text-align: center;
`;
