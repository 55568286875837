import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { default as MuiSwitch } from '@mui/material/Switch';
import { FONT_MEDIUM, PRIMARY_COLOR } from '../../shared/styles';

/**
 * Toggle Switch
 * @param {Boolean} checked Toggle value
 * @param {Function} handleChecked On handle switch value
 * @param {String} textLeft Text to show on left side of switch
 * @param {String} textRight Text to show on right side of switch
 * Referenced to MUI Switch
 */
const Switch = ({
  name,
  checked,
  handleChange,
  textLeft = 'Off',
  textRight = 'On',
  ...props
}) => {
  const onHandle = (event) => handleChange(event.target.checked);
  return (
    <ToggleWrapper
      checked={checked}
      container
      alignItems="center"
      spacing={1}
      {...props}
    >
      <p className={!checked ? 'active' : ''}>{textLeft}</p>
      <AntSwitch checked={checked} onChange={onHandle} name={name} />
      <p className={checked ? 'active' : ''}>{textRight}</p>
    </ToggleWrapper>
  );
};

const AntSwitch = styled(MuiSwitch)(() => ({
  '&.MuiSwitch-root': {
    width: 40,
    height: 25,
    padding: 0,
    display: 'flex',
    margin: '0 10px',
  },
  '& .MuiSwitch-switchBase': {
    position: 'absolute',
    padding: 2,
    color: PRIMARY_COLOR,
    marginTop: '2px',
    marginLeft: '2px',

    '&.Mui-checked': {
      transform: 'translateX(17px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: PRIMARY_COLOR,
        borderColor: PRIMARY_COLOR,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    width: 16,
    height: 16,
    boxShadow: 'none',
  },
  '& .MuiSwitch-track': {
    border: `1px solid ${PRIMARY_COLOR}`,
    borderRadius: 25 / 2,
    opacity: 1,
    backgroundColor: '#FFFFFF',
    height: 22,
  },
}));

const ToggleWrapper = styled.div`
  display: flex;
  p {
    color: rgba(21, 28, 48, 0.6);
    margin: 0;
    &.active {
      color: rgba(21, 28, 48, 1);
      ${FONT_MEDIUM}
    }
  }
`;

Switch.propTypes = {
  name: PropTypes.string,
  checked: PropTypes.bool,
  handleChange: PropTypes.func,
  textLeft: PropTypes.string,
  textRight: PropTypes.string,
};

export default Switch;
