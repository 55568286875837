import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { getCellContent } from './Pricing.helper';

const PricingTableCustomRow = ({ row }) => {
  const { t } = useTranslation();
  return (
    <div className="table-row">
      <div className="table-cell-title">
        {t(row.name)}
        {row.info && (
          <Tooltip title={t(row.info)} placement="top" arrow>
            <PricingTableInfoTool />
          </Tooltip>
        )}
      </div>
      <div className="table-cell-1">{getCellContent(row.isBasic, t)}</div>
      <div className="table-cell-2">{getCellContent(row.isStandard, t)}</div>
      <div className="table-cell-3">{getCellContent(row.isPlus, t)}</div>
      <div className="table-cell-4">{getCellContent(row.isPro, t)}</div>
    </div>
  );
};
const PricingTableInfoTool = styled(InfoOutlinedIcon)`
  color: #a3a3a3;
  height: 15px !important;
  margin-left: 6px;
  width: 15px !important;
`;
PricingTableCustomRow.propTypes = {
  row: PropTypes.object,
};
export default PricingTableCustomRow;
