import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { P } from '../../components/Text';

const PricingInfo = () => {
  const { t } = useTranslation();
  return (
    <PricingInfoWrapper>
      <P align="start">{t('pricing.info.info_one_star')}</P>
      <P align="start">{t('pricing.info.info_two_star')}</P>
    </PricingInfoWrapper>
  );
};
export default PricingInfo;

const PricingInfoWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-bottom: 3rem;
  margin-top: 2rem;
  p {
    font-size: 13px;
    margin-bottom: 10px;
  }
`;
